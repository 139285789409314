// 
// user.js
// Use this to write your custom JS
//


// 
// user.js
// Use this to write your custom JS
//

//console.log("hellooooooooo");

//console.log(document.querySelector('input[name="purchase"]:checked').value);





var mainColor = '#9FD800';




var screenWidth = window.innerWidth;
var screenMode = 0;
// Desktop: 0     Mobile: 1
var elem = document.getElementsByClassName('deskTemp');
var slider = document.getElementById('screenSlider');
var clone = [];
var slideTemp = [];

function screenSizeDetect(){
    //768px
    screenWidth = window.innerWidth;
    if(screenWidth < 768){
        //console.log("mobile!");
        screenMode = 1;
    }else{
        //console.log("desktop!");
        screenMode = 0;
    }
}


function mobileMode(currState){
    if(currState == 1){
        // MOBILE
        for (var i = 0; i < elem.length; i++) {
            //elem[i].style.display = 'none';
        }
    }else if(currState == 0){
        // DESKTOP
        for (var i = 0; i < elem.length; i++) {
        }

    }
}




var navBar = document.getElementById('navBar');
var mainTop = document.getElementById('main');
function navBarStyle(scroll){
    if(scroll  < 50){
        mainTop.style.margin = '0rem';
        mainTop.style.borderRadius = '0rem';
    }else{
        mainTop.style.margin = '2rem 2rem 2rem 2rem';
        mainTop.style.borderRadius = '2rem 2rem 2rem 2rem';
    }

    if(scroll < 300){
        navBar.style.backgroundColor  = 'transparent';
        navBar.style.borderBottom = '1px solid rgba(0,0,0,0)';
    }else{
        navBar.style.backgroundColor  = 'white';
        navBar.style.borderBottom = '1px solid rgba(0,0,0,0.1)';
    }

}



window.onload = function(){
    ArraySetting();
    slideElementSetting();
    /////////

    // for (var i = 0; i < elem.length; i++) {
    //     slideTemp[i].appendChild(clone[i]);
    //     //console.log(elem.length);
    //     console.log(elem);
    // }




    //console.log(slideTemp[0].children);

    //////// CHECK /////////
    for (var i = 0; i < 4; i++) {
        //console.log(imgSrcArray[i]);
        //console.log(radioArray[i]);
        //console.log(cardArray[i]);
    }
    //console.log(radioArray[0][0]);
    //console.log(imgSrcArray[0]);
}



function live() {
    // checkRadio(screenMode);
    // ArrayCompare();

    // console.log("previous: ",prevChangeArray[0],prevChangeArray[1],prevChangeArray[2],prevChangeArray[3]);
    // console.log("current : ",currChangeArray[0],currChangeArray[1],currChangeArray[2],currChangeArray[3]);
    // console.log(" ");



    screenSizeDetect();
    //mobileMode(screenMode);


    //console.log(window.pageYOffset);
    navBarStyle(window.pageYOffset);
  }
setInterval(live, 100);

